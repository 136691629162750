<template>
  <b-container class="my-4">
    <b-row class="text-center mb-4" align-h="center" v-if="loading">
      <b-col cols="1">
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card v-if="!loading">
          <b-container>
            <b-row>
              <b-col sm="12" md="7">
                <p>
                  <b-link class="mr-3" :to="{ name: 'ImpactInitiatives' }">all initiatives</b-link>
                  <b-button class="mr-3" v-if="permission.edit" variant="outline-primary" :to="{ name: 'FormEdit', params: { id: item.id, model: 'impactinitiative' }}">Edit</b-button>
                  <delete-standard
                    v-if="permission.delete"
                    :id="item.id"
                    model="impactinitiative"
                  ></delete-standard>
                </p>
                <h2>
                  {{item.name}}
                </h2>
                <div class="mb-2">
                    {{item.country}} | {{item.impactstatus.name}}
                </div>
                <div class="mb-2" style="font-size: 1.2em; font-weight: 500">
                  {{item.headline}}
                </div>
                <div v-html="item.description"></div>
              </b-col>
              <b-col>
                <h4>Experts</h4>
                <div v-for="expert in item.impactexperts" :key="'expert'+expert.id" style="font-size: 1.2em">
                  <router-link :to="{ name: 'ImpactExpert', params: { id: expert.id }}">{{expert.name}}</router-link>
                </div>
                <div v-if="item.issued">
                  <h4 class="mt-5">Issued date: {{moment(item.issued).format('YYYY-MM-DD')}}</h4>
                </div>
                <div v-if="item.effective">
                  <h4 class="mt-2">Effective date: {{moment(item.effective).format('YYYY-MM-DD')}}</h4>
                </div>
                <div v-if="item.first_reporting">
                  <h4 class="mt-2">First reporting date: {{moment(item.first_reporting).format('YYYY-MM-DD')}}</h4>
                </div>
                <h4 class="mt-5">Topics</h4>
                <div v-for="topic in item.impacttopics" :key="'topic'+topic.id" style="font-size: 1.2em">
                  <router-link :to="{ name: 'ImpactTopic', params: { id: topic.id }}">{{topic.name}}</router-link>
                </div>
                <div v-if="item.regulators.length > 0">
                  <h4 class="mt-5">Regulators</h4>
                  <div v-for="row in item.regulators" :key="'entity'+row.id" class="mb-2">
                    <strong>{{row.name}}</strong><br />{{row.comments}}
                  </div>
                </div>
                <div v-if="item.reportingframeworks.length > 0">
                  <h4 class="mt-5">Reporting frameworks</h4>
                  <div v-for="row in item.reportingframeworks" :key="'entity'+row.id" class="mb-2">
                    {{row.name}}
                  </div>
                </div>
                <div v-if="item.assurance">
                  <h4 class="mt-5">Assurance</h4>
                  <div>{{item.assurance.name}}</div>
                </div>
                <div v-if="item.entities.length > 0">
                  <h4 class="mt-5">Entities</h4>
                  <div v-for="row in item.entities" :key="'entity'+row.id" class="mb-2">
                    {{row.name}}
                  </div>
                </div>
                <div v-if="item.events.length > 0">
                  <h4 class="mt-5">Events</h4>
                  <div v-for="event in item.events" :key="'event'+event.id" class="mb-3">
                    <router-link :to="{ name: 'Event', params: { id: event.id }}"><strong>{{moment(event.date).format('YYYY-MM-DD')}}</strong> - {{event.name}}</router-link>
                  </div>
                </div>
                <div v-if="item.regulations.length > 0">
                  <h4 class="mt-5">Regulations</h4>
                  <div v-for="row in item.regulations" :key="'regulation'+row.id" class="mb-3">
                    <router-link :to="{ name: 'RegDecompWorkspace', params: { id: row.id }}">{{row.name_short}}</router-link>
                  </div>
                </div>
                <div v-if="item.articles.length > 0">
                  <h4 class="mt-5">Insights</h4>
                  <div v-for="row in item.articles" :key="'article'+row.id" class="mb-3">
                    <router-link :to="{ name: 'Article', params: { id: row.id }}">{{row.title}}</router-link>
                  </div>
                </div>
                <div v-if="item.publications.length > 0">
                  <h4 class="mt-5">News</h4>
                  <div v-for="row in item.publications" :key="'newsitem'+row.id" class="mb-3">
                    <router-link :to="{ name: 'NewsItem', params: { id: row.id }}">{{row.name}}</router-link>
                  </div>
                </div>
                <div v-if="item.impactinitiativeslinks.length > 0">
                  <h4 class="mt-5">External links</h4>
                  <div v-for="link in item.impactinitiativeslinks" :key="'link'+link.id" class="mb-3">
                    <div><a :href="link.url" target="_blank">{{link.name}}</a></div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ac from '@/libs/accesscontrol'
import DeleteStandard from '@/components/DeleteStandard'
import moment from 'moment'

export default {
  name: 'ImpactInitiative',
  components: {
    DeleteStandard
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.$stat.log({ page: 'impact initiative', action: 'open impact initiative', model: 'impactinitiative', model_id: this.$route.params.id })
    this.permission.delete = ac.can(this.user.acgroups).deleteAny('impactinitiative').granted
    this.permission.edit = ac.can(this.user.acgroups).updateAny('impactinitiative').granted
    this.load()
  },
  data () {
    return {
      item: null,
      loading: true,
      moment: moment,
      permission: {
        delete: false,
        edit: false
      }
    }
  },
  methods: {
    load: async function () {
      this.loading = true
      try {
        let apiName = 'cosmos'
        let path = `/impact/initiative/${this.$route.params.id}`
        this.item = await this.$Amplify.API.get(apiName, path)
        console.log(this.item)
        this.loading = false
      } catch (e) {
        this.$logger.warn(e)
      }
    }
  }
}
</script>

<style scoped>

</style>
